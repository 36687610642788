import {
    createRouter,
    createWebHashHistory
} from 'vue-router'

import Layout from '../Layout/Layout.vue'
const routes = [
    {
        path: '/login',
        name: 'login',
        hidden: true,
        meta: { title: '商超中心运维管理系统 | 苏州' },
        component: () => import('@/views/Login/index.vue')
    },
    {
        path: '/jyf',
        component: Layout,
        redirect: '/',
        meta: {
            title: '监优服-商超中心',
            icon: 'icon-a-gongzuotai2x',
        },
        children: [
            {
                path: '/jyf/home',
                component: () => import('@/views/Home/index.vue'),
                name: '/jyf/home',
                meta: { title: '商超运营统计 ', affix: true },
            },
            {
                path: '/jyf/supermarket',
                component: () => import('@/views/JYF/Supermarket/index.vue'),
                name: '/jyf/supermarket',
                meta: { title: '商超入驻信息 ' },
            },
            {
                path: '/jyf/supermarket/info',
                component: () => import('@/views/JYF/Supermarket/info.vue'),
                name: '/jyf/supermarket/info',
                meta: { title: '商超入驻信息-详情 '},
                hidden: true,
            },
            {
                path: '/jyf/locker',
                component: () => import('@/views/JYF/Locker/index.vue'),
                name: '/jyf/locker',
                meta: { title: '商超解锁记录'},
            },
            {
                path: '/jyf/locker/log',
                component: () => import('@/views/JYF/Locker/log.vue'),
                name: '/jyf/locker/log',
            hidden: true,
                meta: { title: '商超解锁详情'},
            },
            {
                path: '/jyf/order',
                component: () => import('@/views/JYF/Order/index.vue'),
                name: '/jyf/order',
                meta: { title: '商超订单查询' },
            },
            {
                path: '/jyf/order/deatils',
                component: () => import('@/views/JYF/Order/deatils.vue'),
                name: '/jyf/order/deatils',
                hidden: true,
                meta: { title: '商超订单查询' },
            },
            {
                path: '/jyf/product',
                component: () => import('@/views/JYF/Product/index.vue'),
                name: '/jyf/product',
                meta: { title: '商超商品查询'},
            },
            {
                path: '/jyf/announcement',
                component: () => import('@/views/JYF/Announcement/index.vue'),
                name: '/jyf/announcement',
                meta: { title: '商超系统公告' },
            },
            {
                path: '/SystemDocument/index',
                name: '/SystemDocument/index ',
                component: () => import('@/views/SystemDocument/index.vue'),
                meta: { title: '商超文档管理', icon: 'icon-a-gongzuotai2x' }
            }
            
        ]
    },
    // {
    //     path: '/SystemDocument',
    //     name: '商超文档管理',
    //     component: Layout,
    //     redirect: '/SystemDocument/index',
    //     hidden: true,
    //     children: [
    //         {
    //             path: '/SystemDocument/index',
    //             name: '/SystemDocument/index ',
    //             component: () => import('@/views/SystemDocument/index.vue'),
    //             meta: { title: '商超文档管理', icon: 'icon-a-gongzuotai2x' }
    //         }
    //     ]
    // },
]

const router = createRouter({
    history: createWebHashHistory(),
    routes
})

// 全局守卫：登录拦截 本地没有存token,请重新登录
router.beforeEach((to, from, next) => {
    // 判断有没有登录
    if (!window.localStorage.getItem('token')) {
        if (to.name == "login"|| to.name == "registered" || to.name == "changePassword") {
            next();
        } else {
            next("/login")
        }
    } else {
        if (to.fullPath === '/') {
            next("/jyf/home")
        } else {
            next();
        }
    }
    if (to.meta.title) {
        document.title = '商超中心运维管理系统 | 苏州'
    }
});

export default router

